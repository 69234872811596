<template>
  <PageWithLayout :isBodyBg="false">
    <div class="group_box">
      <BoxView title="search" :grid="100">
        <SearchComponent
          :searchDataList.sync="searchData.searchDataList"
          @onSearch="onSearch()"/>
      </BoxView>
      <BoxView title="search + tab" :grid="100">
        <SearchComponent
          :tabData="tabData"
          :searchDataList.sync="searchTabData[tabData.value].searchDataList"
          @onSearch="onSearch()"
          @onUpdateTab="value => onUpdateTab(value)"/>
      </BoxView>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import BoxView from '@lemontree-ai/lemontree-admin-common-front/components/common/boxView/BoxView';
import SearchComponent from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';

export default {
  name:'Search',
  components:{
    PageWithLayout,
    BoxView,
    SearchComponent,
  },
  data(){
    return{
      searchData: {
        searchDataList:[
          {
            title:'RadioInput',
            type: 'RadioInput',
            dataList:[
              { id:'radio1', text:'radio1' },
              { id:'radio2', text:'radio2' },
              { id:'radio3', text:'radio3' },
            ],
            maxWidth:"300px",
            isFull:true,
            value:'',
            value2:'radio1'
          },
          {
            title:'SelectboxInput',
            type: 'SelectboxInput',
            dataList:[
              { id:0, text:'data1' },
              { id:1, text:'data2' },
            ],
            maxWidth:"300px",
            isFull:true,
            value:null,
            value2:''
          },
          {
            title:'Radio',
            type: 'Radio',
            dataList:[
              { id:'radio1', text:'radio1' },
              { id:'radio2', text:'radio2' },
            ],
            isFull:true,
            value:'',
          },
          {
            title:'Checkbox',
            type: 'Checkbox',
            dataList:[
              { id:'checkbox1', text:'checkbox1' },
              { id:'checkbox2', text:'checkbox2' },
            ],
            isFull:true,
            value:[],
          },
          {
            title:'Selectbox',
            type: 'Selectbox',
            dataList:[
              { id:0, text:'data1' },
              { id:1, text:'data2' },
            ],
            maxWidth:"300px",
            isFull:true,
            value:''
          },
          {
            title:'Input',
            type: 'Input',
            maxWidth:"300px",
            isFull:true,
            value:'',
          },
          {
            title:'DatePicker',
            type: 'DatePicker',
            maxWidth:"300px",
            isFull:true,
            value:'',
          },
          {
            title:'DateRangeQuick',
            type: 'DateRangeQuick',
            maxWidth:"300px",
            isFull:true,
            value:[]
          },
        ]
      },
      tabData: {
        dataList: [
          { id: 'tab1', text:'tab1' },
          { id: 'tab2', text:'tab2' },
          { id: 'tab3', text:'tab3' },
        ],
        option:{
          isCount: false
        },
        value:'tab1'
      },
      searchTabData: {
        tab1:{
          searchDataList:[
            {
              title:'Selectbox',
              type: 'Selectbox',
              dataList:[
                { id:0, text:'data1' },
                { id:1, text:'data2' },
              ],
              maxWidth:"300px",
              isFull:true,
              value:''
            },
          ]
        },
        tab2:{
          searchDataList:[
            {
              title:'Radio',
              type: 'Radio',
              dataList:[
                { id:'radio1', text:'radio1' },
                { id:'radio2', text:'radio2' },
              ],
              isAddDataListAll: true,
              maxWidth:"300px",
              isFull:true,
              value:null
            },
          ]
        },
        tab3:{
          searchDataList:[
            {
              title:'DateRangeQuick',
              type: 'DateRangeQuick',
              maxWidth:"300px",
              isFull:true,
              value:[]
            },
            {
              title:'Radio',
              type: 'Radio',
              dataList:'settled_type',
              isAddDataListAll: true,
              maxWidth:"300px",
              isFull:true,
              value:null
            },
            {
              title:'Selectbox',
              type: 'Selectbox',
              dataList:[
                { id:0, text:'data1' },
                { id:1, text:'data2' },
              ],
              maxWidth:"300px",
              isFull:true,
              value:''
            },
          ]
        }
      }
    }
  },
  methods:{
    onSearch(){},
    onUpdateTab(){},
  }
}
</script>

<style scoped>
/* svg icon */
.list_icon{text-align:center}
.item_icon{display:inline-block;width:120px;margin:4px;padding:14px 8px;border:1px solid #e1e1e1;vertical-align:top}
.item_icon .tit_icon{display:block;margin-top:14px;font-size:14px;line-height:16px}
</style>